import cx from 'classnames'

import {
  SanityGrid,
  SanityGridBackground,
} from '@data/sanity/queries/types/modules'
import { getGridSizeClass, getItemOrderClass } from '@lib/dom'

import GridBlock from './grid-block'

type GridProps = SanityGrid

type ColorClassMap = Record<SanityGridBackground, string | string[]>

const gridColorClasses: ColorClassMap = {
  [SanityGridBackground.GRADIENT]:
    'bg-gradient-to-br from-gradient-from to-gradient-to text-white',
  [SanityGridBackground.LIGHT_GRAY]: 'bg-gray-light text-black',
  [SanityGridBackground.GRAY]: 'bg-gray text-black',
  [SanityGridBackground.DARK_GRAY]: 'bg-gray-dark text-white',
  [SanityGridBackground.RED]: 'bg-red text-white',
  [SanityGridBackground.DARK_RED]: 'bg-red-dark text-white',
  [SanityGridBackground.BLUE]: 'bg-blue text-white',
  [SanityGridBackground.DARK_BLUE]: 'bg-blue-dark text-white',
}

const Grid = ({
  size,
  columns: rawColumns,
  reverseSequence,
  style,
  background,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = rawColumns.map((column, columnIndex) => {
    const columnClasses = column.sizes.map(
      ({ breakpoint, width, justify, align, start }) =>
        getGridSizeClass(breakpoint, width, justify, align, start)
    )
    const orderClasses = column.sizes.map(({ breakpoint, width }) =>
      getItemOrderClass(columnIndex, breakpoint, width, size, reverseSequence)
    )

    return {
      ...column,
      classes: [...columnClasses, ...orderClasses],
    }
  })

  const fillStyle = 'bg-box-bg'
  const outlineStyle = 'border border-box-border'

  return (
    <section className={`${background ? gridColorClasses[background] : ''}`}>
      <div className="relative container py-10 sm:py-12 md:py-16 lg:py-20">
        <div className="mx-auto">
          <div
            className={cx(
              `grid grid-cols-${size} gap-x-4 gap-y-8 sm:gap-x-8 lg:gap-x-12 lg:gap-y-12`,
              {
                [`${fillStyle}`]: style === 'fill',
                [`${outlineStyle}`]: style === 'outline',
                '!gap-x-0': noColumnGaps,
                '!gap-y-0': noRowGaps,
              }
            )}
          >
            {columns.map(({ _key, blocks, classes, style }) => (
              <div
                key={_key}
                className={cx(classes, {
                  'px-6 py-6 sm:px-8 sm:py-10': style,
                  [`${fillStyle}`]: style === 'fill',
                  [`${outlineStyle}`]: style === 'outline',
                })}
              >
                {blocks.map((block) => (
                  <GridBlock key={block._key} block={block} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
