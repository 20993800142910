import { useContext, useMemo } from 'react'
import {
  FieldError,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormClearErrors,
} from 'react-hook-form'

import { compareStrings } from '@lib/helpers'
import { SchoolContext } from '@lib/school'
import { ShopFormStringsContext } from '@lib/strings'

import InputSearchDropdown, {
  SearchDropdownOption,
} from '@components/input-search-dropdown'
import InputField from '@components/input-field'
import { ShopStep1FormValues } from './step-1'

interface ShopFormProps {
  register: UseFormRegister<ShopStep1FormValues>
  setValue: UseFormSetValue<ShopStep1FormValues>
  getValues: UseFormGetValues<ShopStep1FormValues>
  clearErrors: UseFormClearErrors<ShopStep1FormValues>
  errors: {
    name?: FieldError
    schoolId?: FieldError
  }
}

/**
 * Shop form section component.
 */
const ShopForm = ({
  register,
  setValue,
  getValues,
  clearErrors,
  errors,
}: ShopFormProps) => {
  const { schools } = useContext(SchoolContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)

  const schoolOptions = useMemo<SearchDropdownOption[]>(
    () =>
      schools
        .map((school) => ({ value: school.id, label: school.name }))
        .sort((school1, school2) =>
          compareStrings(school1.label, school2.label)
        ),
    [schools]
  )

  const nameRegister = register('name', {
    required: shopFormStrings.shopFormNameMissing,
  })

  register('schoolId', {
    required: shopFormStrings.shopFormSchoolMissing,
  })

  const handleSchoolIdChange = (value: string) => {
    clearErrors('schoolId')
    setValue('schoolId', value)
  }

  return (
    <div>
      <h2 className="font-semibold text-center mb-8">
        {shopFormStrings.shopFormShopSectionTitle}
      </h2>

      <InputField
        className="mb-4"
        id="create-shop-name"
        type="text"
        formRegister={nameRegister}
        errorMessage={errors.name?.message}
        label={shopFormStrings.shopFormName}
        placeholder={shopFormStrings.shopFormNamePlaceholder}
      />

      <InputSearchDropdown
        className=""
        id="create-shop-school"
        value={getValues('schoolId')}
        options={schoolOptions}
        errorMessage={errors.schoolId?.message}
        label={shopFormStrings.shopFormSchool}
        placeholder={shopFormStrings.shopFormSchoolPlaceholder}
        onChange={handleSchoolIdChange}
      />
    </div>
  )
}

export default ShopForm
