import { useContext, useMemo } from 'react'

import {
  SanityPrintType,
  SanityPrintShopType,
} from '@data/sanity/queries/types/shop'
import { SanityShopFormStrings } from '@data/sanity/queries/types/strings'
import { getPrintAssetSections } from '@lib/print'
import { ShopFormContext } from '@lib/shop'
import { ShopFormStringsContext } from '@lib/strings'

import Button, { ButtonColor, ButtonVariant } from '@components/buttons/button'
import ShopFormPrintUploadSection from './print-upload-section'

interface ShopFormStep2Props {
  showSkipButton: boolean
}

/**
 * Gets the title of an upload section.
 */
const getUploadSectionTitle = (
  shopFormStrings: SanityShopFormStrings,
  type: SanityPrintType,
  isOptimized: boolean,
  index: number
) => {
  const sectionTitleMap = {
    [SanityPrintType.LOGO]: {
      '0': shopFormStrings.shopFormLogoUploadSectionTitle,
      '1': shopFormStrings.shopFormOptimizedLogoUploadSectionTitle,
    },
    [SanityPrintType.IMAGE]: {
      '0': shopFormStrings.shopFormImageUploadSectionTitle,
      '1': shopFormStrings.shopFormOptimizedImageUploadSectionTitle,
    },
  }

  const title = sectionTitleMap[type][isOptimized ? '1' : '0'] ?? ''

  return title.replace(/{index}/gi, `${index}`)
}

/**
 * Gets the description of an upload section.
 */
const getUploadSectionDescription = (
  shopFormStrings: SanityShopFormStrings,
  type: SanityPrintType,
  isOptimized: boolean
) => {
  const sectionDescriptionMap = {
    [SanityPrintType.LOGO]: {
      '0': shopFormStrings.shopFormLogoUploadSectionDescription,
      '1': shopFormStrings.shopFormOptimizedLogoUploadSectionDescription,
    },
    [SanityPrintType.IMAGE]: {
      '0': shopFormStrings.shopFormImageUploadSectionDescription,
      '1': shopFormStrings.shopFormOptimizedImageUploadSectionDescription,
    },
  }

  const description = sectionDescriptionMap[type][isOptimized ? '1' : '0'] ?? []

  return description
}

const ShopFormStep2 = ({ showSkipButton }: ShopFormStep2Props) => {
  const { printPermissions, shopState, setStep } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)

  const printAssetSections = useMemo(
    () => getPrintAssetSections(shopState.type, printPermissions),
    [printPermissions, shopState.type]
  )

  const stepDescription =
    shopState.type === SanityPrintShopType.TEAM
      ? shopFormStrings.shopFormTeamPrintSectionDescription
      : shopFormStrings.shopFormYearPrintSectionDescription
  const printCount = shopState.prints?.length ?? 0

  return (
    <div className="max-w-[900px] mx-auto">
      <div className="text-center mb-14">
        <h2 className="font-semibold mb-6">
          {shopFormStrings.shopFormPrintSectionTitle}
        </h2>
        {stepDescription && <p className="text-lg">{stepDescription}</p>}

        {showSkipButton && (
          <Button
            className="mt-10"
            variant={ButtonVariant.PRIMARY}
            color={ButtonColor.RED}
            icon="ArrowRight"
            onClick={() => setStep(3)}
          >
            {shopFormStrings.shopFormSkipStep2Button}
          </Button>
        )}
      </div>

      <div>
        {printAssetSections.map((printAssetSection, sectionIndex) => (
          <ShopFormPrintUploadSection
            key={`print-upload-section-${printAssetSection.type}-${
              printAssetSection.isOptimized ? 'optimized' : 'non-optimized'
            }`}
            title={getUploadSectionTitle(
              shopFormStrings,
              printAssetSection.type,
              printAssetSection.isOptimized,
              sectionIndex + 1
            )}
            description={getUploadSectionDescription(
              shopFormStrings,
              printAssetSection.type,
              printAssetSection.isOptimized
            )}
            type={printAssetSection.type}
            slotTypes={printAssetSection.slotTypes}
            isOptimized={printAssetSection.isOptimized}
          />
        ))}
      </div>

      <div className="flex justify-center">
        <Button
          variant={ButtonVariant.PRIMARY}
          color={ButtonColor.RED}
          icon="ArrowRight"
          onClick={() => setStep(3)}
          disabled={printCount === 0}
        >
          {shopFormStrings.shopFormStep2Submit}
        </Button>
      </div>
    </div>
  )
}

export default ShopFormStep2
