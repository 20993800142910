import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import BlockContent from '@sanity/block-content-to-react'

import { ParseStatus } from '@lib/shopify/graphql/client'
import { SignupFormValues, useSubmitSignupForm, useUser } from '@lib/auth'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import {
  createPrivateShop,
  ShopFormContext,
  CreateShopFormValues,
  ShopActionType,
} from '@lib/shop'
import { ShopFormStringsContext, StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, { ButtonColor, ButtonVariant } from '@components/buttons/button'
import ShopForm from './shop-form'
import UserForm from './user-form'

export type ShopStep1FormValues = CreateShopFormValues & SignupFormValues

const ShopFormStep1 = () => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { shopState, dispatchShopState, setStep } = useContext(ShopFormContext)
  const { authStrings, shopFormStrings } = useContext(ShopFormStringsContext)
  const { user } = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [isShopError, setIsShopError] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<ShopStep1FormValues>()
  const [
    signupErrorStatus,
    isSignupError,
    isSignupLoading,
    signupErrorMessages,
    handleSignupSubmit,
  ] = useSubmitSignupForm()

  // Load user data
  useEffect(() => {
    if (!user || isLoading) {
      return
    }

    dispatchShopState({
      action: ShopActionType.UPDATE_USER,
      user: {
        isLoggedIn: user.isLoggedIn,
        email: user.email,
      },
    })
  }, [dispatchShopState, isLoading, user])

  // Handle shop creation
  const createShop = async (values: ShopStep1FormValues) => {
    const [shop, uploadedPrints] = await createPrivateShop(locale, {
      email: shopState.user?.email ?? values.email,
      type: shopState.type,
      name: values.name,
      schoolId: values.schoolId,
    })

    // Show error on shop creation failure
    if (!shop) {
      setIsShopError(true)
      setIsLoading(false)
      return
    }

    dispatchShopState({
      action: ShopActionType.UPDATE_SHOP_DETAILS,
      shopId: shop.shopId,
      id: shop.id,
      name: shop.name,
      schoolId: shop.schoolId,
    })
    uploadedPrints?.forEach((uploadedPrint) =>
      dispatchShopState({
        action: ShopActionType.ADD_PRINT,
        id: uploadedPrint.id,
        image: uploadedPrint.image,
        filename: uploadedPrint.filename,
        title: uploadedPrint.title,
        slotTypes: uploadedPrint.slotTypes,
        type: uploadedPrint.type,
        color: uploadedPrint.color,
        isOptimized: uploadedPrint.isOptimized,
      })
    )

    // Complete step
    setStep(2)
  }

  // Handle form submission
  const onSubmit = async (
    values: ShopStep1FormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    setIsLoading(true)
    setIsShopError(false)

    // Check if user is not logged in
    if (!shopState.user?.isLoggedIn) {
      // Create a new user
      await handleSignupSubmit(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
        },
        async () => {
          // Create shop, if user was created successfully
          await createShop(values)
        }
      )

      setIsLoading(false)
      return
    }

    // Create shop for the current user
    await createShop(values)

    setIsLoading(false)
  }

  const isDisabled =
    !!errors.name ||
    !!errors.schoolId ||
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.email ||
    !!errors.password

  return (
    <form className="max-w-[400px] mx-auto" onSubmit={handleSubmit(onSubmit)}>
      <ShopForm
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        clearErrors={clearErrors}
      />

      <UserForm
        register={register}
        signupErrorMessages={signupErrorMessages}
        errors={errors}
        className="mt-16"
      />

      {isSignupError && (
        <div key="error" className="mt-8">
          <Alert error>
            <BlockContent
              renderContainerOnSingleChild
              className="rc-error"
              blocks={
                signupErrorStatus === ParseStatus.INACTIVE_ACCOUNT
                  ? authStrings.signupAccountActivationMessage
                  : authStrings.signupErrorMessage
              }
              serializers={serializers}
            />
          </Alert>
        </div>
      )}

      {isShopError && (
        <div key="error" className="mt-8">
          <Alert error>
            <BlockContent
              className="rc rc-error"
              blocks={shopFormStrings.shopFormStep1ErrorMessage}
              serializers={serializers}
              renderContainerOnSingleChild
            />
          </Alert>
        </div>
      )}

      <div className="flex justify-center mt-10">
        <Button
          type="submit"
          variant={ButtonVariant.PRIMARY}
          color={ButtonColor.RED}
          icon="ArrowRight"
          disabled={isSignupLoading || isLoading || isDisabled}
        >
          {isLoading
            ? strings.buttonSubmitting
            : shopFormStrings.shopFormStep1Submit}
        </Button>
      </div>
    </form>
  )
}

export default ShopFormStep1
